import React, {useState, useEffect} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';
import { useNavigate } from 'react-router-dom';

const FundingForm = () => {
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);
    const navigate  = useNavigate();

    const initialPlan = {
        amount: 0,
        wallet_id: '',
    }
    const [planForm, setPlanForm] = useState(initialPlan);
    const [wallets, setWalets] = useState(null);

    
    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });
    
    const { isLoading: isSendingRequest, mutate: postInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/initiate-funding`, {
            amount: planForm.amount,
            wallet_id: planForm.wallet_id,
          });
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            let redirectUrl = res.data.trax_url;
            setTimeout(() => {
                if (redirectUrl === 'dashboard') {
                    window.location.href= '/';
                }else{
                    // window.location.href= '/funding-payment'+redirectUrl;
                    navigate('/funding-payment'+redirectUrl);
                }
                
            }, 2000);
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

      useEffect(() => {
        let wallets = localStorage.getItem('wallets');
        if (wallets != null) {
            wallets = JSON.parse(wallets);
            setWalets(wallets);
        }   
      }, []);

  return (
    <div id="menu-funding" className="offcanvas offcanvas-bottom offcanvas-attached rounded-bottom">
            <div className="menu-size" style={{height: "540px"}}>
            <div className="d-flex mx-3 mt-3 py-1">
                <div className="align-self-center">
                    <h1 className="mb-0">Account Funding</h1>
                </div>
                <div className="align-self-center ms-auto">
                    <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                        <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                    </a>
                </div>
            </div>
            <div className="card card-style bg-fade-blue border border-fade-blue alert alert-dismissible show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className=" no-click">
                            <i className="bi bi-info-square font-33 color-blue-dark d-block"></i>
                        </div>
                        <div className=" no-click">
                            <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                Fill the form below to fund your account
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="divider divider-margins mt-3"></div>
            <div className="content mt-0">
                <div className="pb-2"></div>
                <div className="form-custom form-label form-icon">
                <i translate="no" className="material-icons-sharp" style={{fontSize: "17px"}}>attach_money</i>
                    <input onChange={onChange} type="number" name="amount" className="form-control bg-theme text-theme rounded-xs" id="amount" placeholder="Amount"/>
                    <label htmlFor="amount" className="color-highlight form-label-always-">Amount to fund</label>
                    <span>(required)</span>
                </div>
                <div className="pb-2"></div>
    
                <div className="pb-2"></div>
                <div className="form-custom form-label form-icon">
                <i translate="no" className="material-icons-sharp" style={{fontSize: "17px"}}>currency_bitcoin</i>
                    <select onChange={onChange} type="text" name="wallet_id" className="form-control bg-theme text-theme rounded-xs" id="wallet" placeholder="wallet">
                        <option value="">Select Payment Method</option>
                        {wallets !== null &&
                        wallets.map(wallet => (
                            <option key={wallet.id} value={wallet.id}> {wallet.wallet_name}</option>
                        ))
                        }
                    </select>
                    <label htmlFor="wallet" className="color-highlight form-label-always-">Payment Method</label>
                    <span>(required)</span>
                </div>
    
                <div className="pb-3"></div>
                <div onClick={postInvestment} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                    {isSendingRequest? <span>sending...</span>
                    :
                    <span>Submit Funding</span>
                    }                            
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default FundingForm