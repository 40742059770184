import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { cardHistory } from '../Request/Histories';
import ReactCardFlip from 'react-card-flip';

const Cards = () => {


    // constructor() {
    //     super();
    //       this.state = {
    //       isFlipped: false
    //     };
    //     this.handleClick = this.handleClick.bind(this);
    //   }
    
      

    const [isFlipped, setFlipped] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setFlipped(!isFlipped);
      }


    const initialCards = {
        number1: 'XXXX',
        number2: 'XXXX',
        number3: 'XXXX',
        number4: 'XXXX',
        name: 'XXXXX XXXXXX',
        expiry: 'XX/XX',
        cvv: 'XXX',
        type: 'visa'
    }
    const updateCradDetails = (card) => {
        setCurrentCard({...currentCard, 
            number1: card.number1,
            number2: card.number2,
            number3: card.number3,
            number4: card.number4,
            name: card.user.name,
            expiry: '08/2029',
            cvv: '745',
            type: card.card_type
        })
    }
    const [currentCard, setCurrentCard] = useState(initialCards);
    let mycardHistory = cardHistory();
    const [pendingcards, setPendingcard] = useState(0);
    const [activecard, setActivecard] = useState(0);
    const [completedcard, setcompletedcard] = useState(0);
    const getDate = (date) => new Date(date).toDateString();

    const setMytcard = (card) => {
      localStorage.setItem('adminhistorycardSelected', JSON.stringify(card));
      const cardEvent = new Event('adminhistorycardSelected');
      window.dispatchEvent(cardEvent);
  }

    useEffect(()=>{
    mycardHistory = cardHistory();
    let pendingNum = 0;        
    let activeNum = 0;        
    let completedNum = 0;  

    mycardHistory.forEach(card => {
            
            if (card.status === 'approved') {
                activeNum += 1;
            }
            if (card.status === 'completed') {
                completedNum += 1;
            }
            if (card.status === 'declined') {
                completedNum += 1;
            }
            if (card.status === 'pending') {
                pendingNum += 1;
            }
            if (card.status === 'waiting approval') {
                pendingNum += 1;
            }
        
    });
    
    setActivecard(activeNum);
    setcompletedcard(completedNum);
    setPendingcard(pendingNum);

        if(window && window.init_template){
            window.init_template();
            var creditCard = document.querySelector('.credit-card');
        if(creditCard){
            creditCard.addEventListener( 'click', function() {
                creditCard.classList.toggle('is-flipped');
            });
        }
        } 

        
    }, [mycardHistory, activecard, completedcard, pendingcards])
  return (
    <div id="page-content">
        
        <div className="row text-center" style={{marginTop: "40px"}}>
        <div className='col-12 col-md-6'>
            <div className="mt-2 content mx-0">
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                    <div onClick={handleClick} className="color-white fw-bolder">
                        <div className="card text-bg-dark" style={{borderRadius: "15px"}}>
                            <img style={{borderRadius: "15px"}} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1676284772/unitcard1.png" className="card-img" alt="..." />
                            <div className="card-img-overlay">
                                <div className='position-absolute w-100 top-0 font-14 d-flex justify-content-between' style={{transform: "translate(0px, 20px)"}}>
                                <div className='font-18'>CRISP ASSET.</div>
                                <div style={{transform: "translate(-65px, 0px)"}} className='text-end'>
                                    <img className='img-fluid' style={{width: '80px'}} src={`${currentCard.type === 'visa'? 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1659807904/1000px-Visa_Inc._logo.svg.png': 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1660665383/mastercardlogo.png'}`}/>
                                </div>
                                </div>

                                <div className="card-text position-absolute fs-3" style={{bottom: '0', transform: "translate(5px, -60px)"}}>
                                    <span className="mx-2">{currentCard.number1}</span> 
                                    <span className="mx-2">{currentCard.number2}</span>
                                    <span className="mx-2">{currentCard.number3}</span>
                                    <span className="mx-2">{currentCard.number4}</span>
                                </div>
                                <div className='position-absolute w-100 bottom-0 font-14 d-flex justify-content-between fw-lighter' style={{transform: "translate(15px, -30px)"}}>
                                <div style={{transform: "translate(0px, 20px)"}}>
                                    <span className='font-11  opacity-50'>Cardholder Name </span><br/><span className='font-20'>{currentCard.name}</span>
                                </div>
                                <div className='text-end' style={{transform: "translate(-65px, 20px)"}}>
                                    <span className='font-11 opacity-50'>Expiration</span><br/><span className='font-20'>{currentCard.expiry}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div onClick={handleClick} className="color-white fw-bolder">
                        <div className="card text-bg-dark" style={{borderRadius: "15px"}}>
                        <img style={{borderRadius: "15px"}} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1676284772/unitcard3.png" className="card-img" alt="..." />
                        <div className="card-img-overlay" style={{borderRadius: "15px"}}>
                            <div className="card-title text-end mt-4"><span className="mx-2">{currentCard.cvv}</span></div>
                            <div className="card-text text-end">Security Code</div>
                        </div>
                        </div>
                    </div>
                    </ReactCardFlip>
            </div>
        </div>
            <div className="col-6 col-md-3">
                <Link to="/invest" data-bs-toggle="offcanvas" data-bs-target="#card-create-menu" className="card card-style mx-2" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i translate="no" className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>add_card</i>
                            </span>
                        <h1 className="font-22 pt-3">Apply Now</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">apply for a card</p>
                    </div>
                </Link>
            </div>
            
            <div className="col-6 col-md-3">
                <Link to="/transfer"  className="card card-style mx-2" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i translate="no" className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>move_up</i>
                            </span>
                        <h1 className="font-22 pt-3">Transfer</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">transfer funds</p>
                    </div>
                </Link>
            </div>
        </div>

        <div className="">
            {pendingcards > 0 && 
            mycardHistory.map(card => (
                <div key={card.id} onClick={() =>{updateCradDetails(card); setMytcard(card);}} data-bs-toggle="offcanvas" data-bs-target="#admin-card-history-menu" className="card pt-2 px-0 rounded-2 shadow-sm my-1 px-3"> 
                    <div className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className={`icon rounded-s me-2 ${card.status === 'pending' ? 'gradient-yellow': 'gradient-green'}  shadow-bg shadow-bg-xs`}>
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>credit_card</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">
                                <span className="mx-2">{card.number1}</span> 
                                <span className="mx-2">{card.number2}</span>
                                <span className="mx-2">{card.number3}</span>
                                <span className="mx-2">{card.number4}</span>
                            </h5>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-yellow-dark">
                                {card.card_type === 'visa'? 
                                <img className='img-fluid' style={{width: '40px'}} src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1659807904/1000px-Visa_Inc._logo.svg.png'/>
                                :
                                <img className='img-fluid' style={{width: '40px'}} src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1660665383/mastercardlogo.png'/>
                                }
                            
                            </h4>
                        </div>
                    </div>
                </div>
            ))
                
            }
                        
        </div>
    </div>
  )
}

export default Cards