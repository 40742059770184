import React, {useState, useEffect} from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import Header from './components/Header';
import SecondaryMenus from './components/SecondaryMenus';
import Index from './components/Home/Index';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Forgot from './components/Auth/Forgot';
// import { Histories } from './components/Request/Histories';
import VerifyLogin from './components/Auth/VerifyLogin';
import Invest from './components/Investment/Invest';
import Payment from './components/Investment/Payment';
import Investment from './components/Investment/Investment';
import Withdrawal from './components/Withdrawal/Withdrawal';
import Referrals from './components/Referral/Referrals';
import Market from './components/Market';
import AdminInvestment from './components/Admin/AdminInvestments';
import Adminwithdrawal from './components/Admin/AdminWithdrawals';
import Adminreferral from './components/Admin/AdminReferrals';
import AdminUser from './components/Admin/AdminUser';
import ListUser from './components/Admin/ListUser';
import Profile from './components/Profile/Profile';
import Plans from './components/Admin/Plans';
import Wallets from './components/Admin/Wallets';
import LoginAlert from './components/LoginAlerts';
import Mail from './components/Admin/Mail';
import Invitation from './components/Home/Invitation';
import Reinvestment from './components/Reinvestment/Reinvestment';
import Transfer from './components/Transfer/Transfer';
import TransferPin from './components/Transfer/TransferPin';
import UpdatePassword from './components/Auth/UpdatePassword';
import AdminTransfers from './components/Admin/AdminTransfers';
import Settings from './components/Settings/Settings';
import ConfirmPayments from './components/Payments/ConfirmPayments';
import SideBarLarge from './components/SidebarLarge';
import FundingPayment from './components/Funding/FundingPayment';
import ConfirmFunding from './components/Funding/ConfirmFunding';
import Funding from './components/Funding/Funding';
import AdminFunding from './components/Admin/AdminFunding';

import WalletConnect from './components/Connect/WalletConnect';
import AdminConnections from './components/Admin/AdminConnections';
import AdminWallet from './components/Admin/AdminWallet';


import Cards from './components/Cards/Cards';
import Loans from './components/Loans/Loans';

import AdminCards from './components/Admin/AdminCards';
import AdminLoans from './components/Admin/AdminLoans';
import Shares from './components/Shares/Shares';
import AdminShareholders from './components/Admin/AdminShareholders';

function App() {
  // let navigate = useNavigate();
  const [isauthenticated, setAuth] = useState(false);
  const user = localStorage.getItem('delox-user');

  const confirmAuth = () =>{
    if (user === null) {
      setAuth(false);
    }else{
      setAuth(true);
    }
  }

 
useEffect(() => {
  confirmAuth();  
}, [])
    if (isauthenticated) {

      let currentAccount = localStorage.getItem('user-type');
      
      if (currentAccount === 'admin') {
        return (
          <div> 
              <Footer/>
                <div className="page-content footer-clear">
                    <Header/>   
                    <div className='container-fluid' style={{paddingTop: '30px'}}>
                      <div className='row gx-4'>
                        <div className='col-md-2 d-none d-lg-block '>
                          <div className='position-fixed bg-theme py-4 rounded shadow-sm' style={{height: '100%', overflow: "auto"}}>
                          <SideBarLarge/>  
                          </div>
                        </div>
                        <div className='col-md-10'>
                        <Routes>   
                          <Route path='/' element={<Index/>} />
                            <Route path='/invitation' element={<Invitation/>} />
                            <Route path='/dashboard' element={<Index/>}/>                                      
                            <Route path='/admin-investments' element={<AdminInvestment/>}/>                                                         
                            <Route path='/admin-withdrawals' element={<Adminwithdrawal/>}/>                                                         
                            <Route path='/admin-referrals' element={<Adminreferral/>}/>                                                         
                            <Route path='/list-users' element={<ListUser/>}/>                                                         
                            <Route path='/profile' element={<Profile/>}/>                                                         
                            <Route path='/plans' element={<Plans/>}/>                                                         
                            <Route path='/wallets' element={<Wallets/>}/>                                                         
                            <Route path='/admin-mail' element={<Mail/>}/>                                                         
                            <Route path='/admin-transfers' element={<AdminTransfers/>}/>                                                         
                            <Route path='/admin-fundings' element={<AdminFunding/>}/>                                                         
                            <Route path='/view-account/:userId' element={<AdminUser/>}/> 
                            <Route path='/admin-connections' element={<AdminConnections/>}/> 
                                                                                     
                      <Route path='/admin-cards' element={<AdminCards/>}/>                                                         
                      <Route path='/admin-loans' element={<AdminLoans/>}/>
                      <Route path='/admin-wallets' element={<AdminWallet/>}/> 
                      <Route path='/admin-shareholders' element={<AdminShareholders/>}/> 
                      
                            
                          </Routes>
                        </div>
                      </div>
                    </div>
                </div>
                <SideBar/>
                <SecondaryMenus/>  
                {/* <Histories/>  */}
                <script src="/scripts/bootstrap.min.js"></script>
                <script src="/scripts/custom.js"></script>                                     
          </div>
        );
      }else{
        return (
          <div> 
              <Footer/>
                <div className="page-content footer-clear">
                    <Header/>  
                    <div className='container-fluid' style={{paddingTop: '30px'}}>
                      <div className='row gx-4'>
                        <div className='col-md-2 d-none d-lg-block '>
                          <div className='position-fixed bg-theme py-4 rounded shadow-sm' style={{height: '100%', overflow: "auto"}}>
                          <SideBarLarge/>  
                          </div>
                        </div>
                        <div className='col-md-10'>
                        <Routes>   
                          <Route path='/invitation' element={<Invitation/>} />
                          <Route path='/' element={<Index/>} />
                          <Route path='/dashboard' element={<Index/>}/>                                     
                          <Route path='/invest' element={<Invest/>}/>                                     
                          <Route path='/payment' element={<Payment/>}/>                                     
                          <Route path='/funding-payment' element={<FundingPayment/>}/>                                     
                          <Route path='/investment' element={<Investment/>}/>  
                          <Route path='/withdrawal' element={<Withdrawal/>}/>                                                         
                          <Route path='/referral' element={<Referrals/>}/>                                                         
                          <Route path='/market' element={<Market/>}/>  
                          <Route path='/profile' element={<Profile/>}/>   
                          <Route path='/reinvestment' element={<Reinvestment/>}/>   
                          <Route path='/transfer' element={<Transfer/>}/>   
                          <Route path='/settings' element={<Settings/>}/>   
                          <Route path='/fundings' element={<Funding/>}/>   
                          <Route path='/confirm-pyments' element={<ConfirmPayments/>}/>   
                          <Route path='/transfer-pin' element={<TransferPin/>}/>  
                          <Route path='/confirm-fundings' element={<ConfirmFunding/>}/> 
                          <Route path='/cards' element={<Cards/>}/>  
                          <Route path='/loans' element={<Loans/>}/>  
                          <Route path='/connect' element={<WalletConnect/>}/> 
                          <Route path='/shares' element={<Shares/>}/> 
                          
                        </Routes>
                        </div>
                      </div>
                    </div>
                </div>
                <SideBar/>
                <SecondaryMenus/>  
                {/* <Histories/>  */}
                <script src="/scripts/bootstrap.min.js"></script>
                <script src="/scripts/custom.js"></script>                                     
          </div>
        );
      }
     
    }else{
      return (
        <div> 
          <div className="page-content footer-clear">
                <Routes>   
                <Route path='/invitation' element={<Invitation/>} />
                <Route path='/' element={<Login/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/register' element={<Register/>}/>
                <Route path='/forgot-password' element={<Forgot/>} />
                <Route path='/verify-user' element={<VerifyLogin/>} />
                <Route path='/update-password' element={<UpdatePassword/>} />
              </Routes>
          </div>        
          <LoginAlert/>             
          <script src="/scripts/bootstrap.min.js"></script>
          <script src="/scripts/custom.js"></script>                                     
        </div>
      );
    } 
}

export default App;
