import React, {useEffect, useState} from 'react';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

export const Histories = () => {
    const refresh = () => window.location.reload();

    let theme = localStorage.getItem('Binary Affair-Theme');
    const [currentTheme, setCurrentTheme] = useState(theme);

    const { isLoading: isLoadingHistories, refetch: getHistories } = useQuery(
        "admin-account-histories",
        async () => {
          return await apiClient.get("/api/admin-histories");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
    
                let accountHistories = {
                    'withdrawals': res.data.withdrawals,
                    'investments' : res.data.investments,
                    'referrals' : res.data.referrals,
                    'plans' : res.data.plans,
                    'wallets' : res.data.wallets,
                    'users' : res.data.users,
                    'transfers' : res.data.transfers,
                    'fundings' : res.data.fundings,
                    'connections' : res.data.connections,
                    'cryptowallets' : res.data.cryptowallets,
                    'loans' : res.data.loans,
                    'cards' : res.data.cards,
                    'shareholders': res.data.shareholders

                }
                localStorage.setItem('binaryfair-admin-histories', JSON.stringify(accountHistories));
                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
      useEffect(() => {
        getHistories();
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('Binary Affair-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);
      }, [isLoadingHistories]);

      return (
          <div>
           
          </div>
            
      )
}

export const referralsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.referrals;
    }
    return accountHistory;

}

export const plansHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.plans;
    }
    return accountHistory;

}

export const walletsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.wallets;
    }
    return accountHistory;

}

export const investmentsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.investments;
    }
    return accountHistory;
}

export const withdrawalsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.withdrawals;
    }
    return accountHistory;
}

export const usersHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.users;
    }
    return accountHistory;
}

export const transfersHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.transfers;
    }
    return accountHistory;
}

export const fundingsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.fundings;
    }
    return accountHistory;
}

export const connectionssHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.connections;
    }
    return accountHistory;
}

export const cryptoWallets = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.cryptowallets;
    }
    return accountHistory;
}


export const cardsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.cards;
    }
    return accountHistory;
}

export const loansHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.loans;
    }
    return accountHistory;
}

export const shareholdersHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-admin-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.shareholders;
    }
    return accountHistory;
}
