import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {fundingsHistory} from '../Request/Admin';

const AdminFunding = () => {
    let myfundingHistory = fundingsHistory();

    const [pendingfundings, setPendingfunding] = useState(0);
    const [activefunding, setActivefunding] = useState(0);
    const [completedfunding, setcompletedfunding] = useState(0);
    const getDate = (date) => new Date(date).toDateString();

    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const setCurrentfunding = (funding) => {
        localStorage.setItem('adminhistoryfundingSelected', JSON.stringify(funding));
        const fundingEvent = new Event('adminhistoryfundingSelected');
        window.dispatchEvent(fundingEvent);
    }
   
    useEffect(() => {
        document.title = 'ADMIN fundingS HISTORY';
        myfundingHistory = fundingsHistory();

        let pendingNum = 0;        
        let activeNum = 0;        
        let completedNum = 0;  

        myfundingHistory.forEach(funding => {
                
                if (funding.status === 'approved') {
                    activeNum += parseInt(funding.amount);
                }
                if (funding.status === 'completed') {
                    completedNum += parseInt(funding.amount);
                }
                if (funding.status === 'declined') {
                    completedNum += parseInt(funding.amount);
                }
                if (funding.status === 'pending') {
                    pendingNum += parseInt(funding.amount);
                }
                if (funding.status === 'waiting approval') {
                    pendingNum += parseInt(funding.amount);
                }
            
        });
        
        setActivefunding(activeNum);
        setcompletedfunding(completedNum);
        setPendingfunding(pendingNum);
        
        if(window && window.init_template){
            window.init_template();
        }  
    }, [myfundingHistory, activefunding, completedfunding, pendingfundings]);

  return (
    <div id="page-content" >        
        <h2 className='text-center mb-2'>
            fundings History
        </h2>
        <div className="card card-style">
            <div className="content mb-0">
                <div className="tabs tabs-pill" id="lend-customers">
                    <div className="tab-controls rounded-m p-1 overflow-visible">
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Declined</a>
                    </div>
                    <div className="mt-3"></div>
                    {/* <!-- Tab Bills List --> */}
                    <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!pendingfundings > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No pending funding records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myfundingHistory.map(funding =>(
                                    (funding.status === 'pending' || funding.status === 'waiting approval') &&
                                    <Link key={funding.id} onClick={()=>setCurrentfunding(funding)} data-bs-toggle="offcanvas" data-bs-target="#admin-funding-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{getAmount(funding.amount)}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(funding.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-red-dark">{funding.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{funding.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>

                <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!activefunding > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No active funding records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myfundingHistory.map(funding =>(
                                    funding.status === 'approved' &&
                                    <Link key={funding.id} onClick={()=>setCurrentfunding(funding)} data-bs-toggle="offcanvas" data-bs-target="#admin-funding-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{getAmount(funding.amount)}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(funding.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-green-dark">{funding.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{funding.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>
                
                {/* <!-- Tab Custom Payments--> */}
                <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
                    <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                    <div className="content mt-0 mb-0">
                        {!completedfunding > 0 ?
                        <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                            <div className="content my-3">
                                <div className="d-flex">
                                    <div className="align-self-center no-click">
                                        <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                    </div>
                                    <div className="align-self-center no-click">
                                        <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                            No completed fundings record avaialable
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                            myfundingHistory.map(funding =>(
                                (funding.status === 'completed' || funding.status === 'declined')  &&
                                <Link key={funding.id} onClick={()=>setCurrentfunding(funding)} data-bs-toggle="offcanvas" data-bs-target="#admin-funding-history-menu"  to="#" className="d-flex pb-3">
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                    </div>
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1">{getAmount(funding.amount)}</h5>
                                        <p className="mb-0 font-11 opacity-50">{getDate(funding.created_at)}</p>
                                    </div>
                                    <div className="align-self-center ms-auto text-end">
                                        <h4 className="pt-1 mb-n1 color-theme">{funding.user.name}</h4>
                                        <p className="mb-0 font-12 opacity-50">{funding.user.email}</p>
                                    </div>
                                </Link>
                            ))                
                        
                        }
                    </div>                   
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminFunding