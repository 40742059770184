import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { investmentsHistory } from '../Request/Histories';
import ChartBalance from '../Reinvestment/ChartBalance';
import EarningSlides from './EarningSlides';

const Investment = () => {
    let myInvestmentHistory = investmentsHistory();

    const [pendingInvestments, setPendingInvestment] = useState(0);
    const [activeInvestment, setActiveInvestment] = useState(0);
    const [completedInvestment, setcompletedInvestment] = useState(0);
    const getDate = (date) => new Date(date).toDateString();

    const getAmount = (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const setCurrentinvestment = (investment) => {
        localStorage.setItem('userhistoryinvestmentSelected', JSON.stringify(investment));
        const investmentEvent = new Event('userhistoryinvestmentSelected');
        window.dispatchEvent(investmentEvent);
    }

    useEffect(() => {
        document.title = 'INVESTMENT HISTORY';
        myInvestmentHistory = investmentsHistory();

        let pendingNum = 0;
        let activeNum = 0;
        let completedNum = 0;

        myInvestmentHistory.forEach(investment => {

            if (investment.status === 'approved') {
                activeNum += parseFloat(investment.amount);
            }
            if (investment.status === 'completed') {
                completedNum += parseFloat(investment.amount);
            }
            if (investment.status === 'pending') {
                pendingNum += parseFloat(investment.amount);
            }
            if (investment.status === 'waiting approval') {
                pendingNum += parseFloat(investment.amount);
            }

        });

        setActiveInvestment(activeNum);
        setcompletedInvestment(completedNum);
        setPendingInvestment(pendingNum);

        if (window && window.init_template) {
            window.init_template();
        }
    }, [myInvestmentHistory, activeInvestment, completedInvestment, pendingInvestments]);

    return (
        <div className="container mx-0 px-sm-4 px-0" style={{ marginTop: '40px' }}>
            <div className=''>
                <EarningSlides />
            </div>

            <div className="row text-center mt-4">


                <div className="col-6 mb-n2">
                    <Link to="/invest" className="card card-style me-0 mx-0" style={{ height: "180px" }}>
                        <div className="card-center">
                            <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                                <i translate="no" className="material-icons-outlined color-white" style={{ fontSize: "28px", marginTop: "11px" }}>attach_money</i>
                            </span>
                            <h1 className="font-22 pt-3">Invest Now</h1>
                        </div>
                        <div className="card-bottom">
                            <p className="font-11 mb-0 opacity-70">click to invest</p>
                        </div>
                    </Link>
                </div>
                <div className="col-6 mb-n2">
                    <Link to="/withdrawal" className="card card-style ms-0 mx-0" style={{ height: "180px" }}>
                        <div className="card-center">
                            <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                                <i translate="no" className="material-icons-outlined color-white" style={{ fontSize: "28px", marginTop: "11px" }}>money_off_csred</i>
                            </span>
                            <h1 className="font-22 pt-3">Withdraw</h1>
                        </div>
                        <div className="card-bottom">
                            <p className="font-11 mb-0 opacity-70">Withdraw trade earnings</p>
                        </div>
                    </Link>
                </div>
            </div>


            <div className="card card-style rounded-4 mx-0">
                <div className="content mb-0">
                    <div className="tabs tabs-pill" id="lend-customers">
                        <div className="tab-controls rounded-m p-1 overflow-visible">
                            <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
                            <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
                            <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Completed</a>
                        </div>
                        <div className="mt-3"></div>
                        {/* <!-- Tab Bills List --> */}
                        <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
                            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                            <div className="content mt-0 mb-0">
                                {!pendingInvestments > 0 ?
                                    <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                        <div className="content my-3">
                                            <div className="d-flex">
                                                <div className="align-self-center no-click">
                                                    <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                                </div>
                                                <div className="align-self-center no-click">
                                                    <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                        No pending investment records found for now
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    myInvestmentHistory.map(investment => (
                                        investment.status === 'pending' || investment.status === 'waiting approval' &&
                                        <Link key={investment.id} onClick={() => setCurrentinvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#user-investment-history-menu" to="#" className="d-flex pb-3">
                                            <div className="align-self-center">
                                                <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                            </div>
                                            <div className="align-self-center ps-1">
                                                <h5 className="pt-1 mb-n1">{getAmount(investment.amount)}</h5>
                                                <p className="mb-0 font-11 opacity-50">{getDate(investment.created_at)}</p>
                                            </div>
                                            <div className="align-self-center ms-auto text-end">
                                                <h4 className="pt-1 mb-n1 color-red-dark">{investment.wallet.wallet_name}</h4>
                                                <p className="mb-0 font-12 opacity-50">{investment.status}</p>
                                            </div>
                                        </Link>
                                    ))

                                }
                            </div>
                        </div>

                        <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
                            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                            <div className="content mt-0 mb-0">
                                {!activeInvestment > 0 ?
                                    <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                        <div className="content my-3">
                                            <div className="d-flex">
                                                <div className="align-self-center no-click">
                                                    <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                                </div>
                                                <div className="align-self-center no-click">
                                                    <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                        No active investment records found for now
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    myInvestmentHistory.map(investment => (
                                        investment.status === 'approved' &&
                                        <Link key={investment.id} onClick={() => setCurrentinvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#user-investment-history-menu" to="#" className="d-flex pb-3">
                                            <div className="align-self-center">
                                                <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                            </div>
                                            <div className="align-self-center ps-1">
                                                <h5 className="pt-1 mb-n1">{getAmount(investment.amount)}</h5>
                                                <p className="mb-0 font-11 opacity-50">{getDate(investment.created_at)}</p>
                                            </div>
                                            <div className="align-self-center ms-auto text-end">
                                                <h4 className="pt-1 mb-n1 color-green-dark">{investment.wallet.wallet_name}</h4>
                                                <p className="mb-0 font-12 opacity-50">{investment.status}</p>
                                            </div>
                                        </Link>
                                    ))

                                }
                            </div>
                        </div>

                        {/* <!-- Tab Custom Payments--> */}
                        <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
                            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                            <div className="content mt-0 mb-0">
                                {!completedInvestment > 0 ?
                                    <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                        <div className="content my-3">
                                            <div className="d-flex">
                                                <div className="align-self-center no-click">
                                                    <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                                </div>
                                                <div className="align-self-center no-click">
                                                    <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                        No completed investment record avaialable
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    myInvestmentHistory.map(investment => (
                                        investment.status === 'completed' &&
                                        <Link key={investment.id} onClick={() => setCurrentinvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#user-investment-history-menu" to="#" className="d-flex pb-3">
                                            <div className="align-self-center">
                                                <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                            </div>
                                            <div className="align-self-center ps-1">
                                                <h5 className="pt-1 mb-n1">{getAmount(investment.amount)}</h5>
                                                <p className="mb-0 font-11 opacity-50">{getDate(investment.created_at)}</p>
                                            </div>
                                            <div className="align-self-center ms-auto text-end">
                                                <h4 className="pt-1 mb-n1 color-theme">{investment.wallet.wallet_name}</h4>
                                                <p className="mb-0 font-12 opacity-50">{investment.status}</p>
                                            </div>
                                        </Link>
                                    ))

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Investment